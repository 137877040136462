/*
 * @Descripttion: 
 * @Author: panyufeng
 * @Date: 2024-12-05 15:13:55
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-12-06 13:57:38
 */
import service from '@/utils/request'

// 查询
export const violationData = (params: any) =>
  service.request({
    method: 'post',
    url: '/violationContract/violationData',
    data: params
  })

// 明细
export const detailData = (params: any) =>
  service.request({
    method: 'post',
    url: '/violationContract/detailData',
    data: params
  })
