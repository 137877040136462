import { ref } from '@vue/runtime-dom'

// 登录状态
export enum isOnline {
  离线 = 0,
  在线
}

export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: true,
    // table 的高度
    height: null,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '序号',
    // table 工具设置选项 配置则默认开启
    tool: {
      // 列表本地储存name
      columnStorageName: 'driver-depot'
    },
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'enterpriseCode',
      label: '企业编号',
      width: '140'
    },
    {
      key: 'name',
      label: '企业名称',
      width: '140'
    },
    {
      key: 'orgName',
      label: '单位名称',
      width: '140'
    },
    {
      key: 'code',
      label: '单位代码',
      width: '140'
    },
    {
      key: 'state',
      label: '状态',
      width: '140',
      slot: 'state'
    },
    {
      key: 'lastContractTime',
      label: '上一次合同办理',
      width: '140'
    },
    {
      key: 'lastViolationTime',
      label: '上一次违章查询',
      width: '140'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 搜索配置
export const inputList = (remoteData1: any, remoteData2: any) => [
  {
    type: 'select',
    label: '企业名称',
    key: 'companyUuid',
    placeholder: '请输入',
    labelWidth: 100,
    inputWidth: 200,
    maxlength: 50,
    options: remoteData1.options,
    isLoading: remoteData1.isLoading,
    isRemote: true,
    isMultiple: false,
    isFilterable: true,
    isCollapseTags: false,
    remoteMethod: remoteData1.remoteMethod,
    rules: {
    }
  },
  {
    type: 'select',
    label: '关联资产',
    key: 'settingId',
    placeholder: '请输入',
    labelWidth: 100,
    inputWidth: 200,
    maxlength: 50,
    options: remoteData2.options,
    isLoading: remoteData2.isLoading,
    isRemote: true,
    isMultiple: false,
    isFilterable: true,
    isCollapseTags: false,
    remoteMethod: remoteData2.remoteMethod,
    rules: {
    }
  }
]

// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 页面基础变量
  const page = {
    queryForm: ref<any>({}), // 搜索参数
    visible: false,
    rowData: null,
    determine: false,
    title: ''
  }
  return {
    ...page
  }
}
