/*
 * @Descripttion: 
 * @Author: panyufeng
 * @Date: 2024-12-25 09:49:10
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-12-27 10:21:21
 */
import service from '@/utils/request'

// 违章API下拉列表
export const enterpriseViolationSettingQuery = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSetting/query',
    data: params
  })

// 违章配置绑定关系分页
export const page = (params: any) =>
  service.request({
    method: 'post',
    url: '/enterpriseViolationSettingCorrelation/page',
    data: params
  })

// 管理员下拉框
export const dropdownOfEnterprise = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/enterprise/dropdownOfEnterprise',
    data: params
  })
