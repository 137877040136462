/*
 * @Description: 系统设置模块路由
 * @Author: yeyouxi
 * @Date: 2021-03-16 10:25:28
 * @LastEditTime: 2024-12-05 15:23:45
 * @LastEditors: panyufeng
 */
import Main from '../../views/main.vue'
const rootName = 'operation-management'

export default [
  {
    path: '/operation-management',
    name: rootName,
    component: Main,
    meta: {
      notCache: true,
      title: '运营管理'
    },
    children: [
      {
        path: 'service-packages',
        name: 'service-packages',
        component: () => import(
          '@/views/singlePage/operation-management/service-packages/index.vue'
        ),
        meta: {
          notCache: true,
          title: '服务套餐',
          root: rootName,
          icon: 'el-icon-chat-dot-round'
        }
      },
      {
        path: 'payment-record',
        name: 'payment-record',
        component: () =>
          import(
            '@/views/singlePage/enterprise-management/payment-record/index.vue'
          ),
        meta: {
          notCache: true,
          title: '缴费记录',
          root: rootName
        }
      },
      {
        path: 'deduction-record',
        name: 'deduction-record',
        component: () =>
          import(
            '@/views/singlePage/enterprise-management/deduction-record/index.vue'
          ),
        meta: {
          notCache: true,
          title: '扣费记录',
          root: rootName
        }
      },
      {
        path: 'new-deduction-record',
        name: 'new-deduction-record',
        component: () =>
          import(
            '@/views/singlePage/enterprise-management/new-deduction-record/index.vue'
          ),
        meta: {
          notCache: true,
          title: '新扣费记录',
          root: rootName
        }
      },
      {
        path: 'admin-feedback-issue',
        name: 'admin-feedback-issue',
        component: () => import('@/views/singlePage/system-config/feedback-issue/index.vue'),
        meta: {
          notCache: true,
          title: '问题反馈',
          root: rootName
        }
      },
      {
        path: 'system-notice',
        name: 'system-notice',
        component: () => import('@/views/singlePage/system-config/system-notice/index.vue'),
        meta: {
          notCache: true,
          title: '系统通知',
          root: rootName
        }
      },
      {
        path: 'violation-data-dashboard',
        name: 'violation-data-dashboard',
        component: () => import('@/views/singlePage/system-config/violation-data-dashboard/index.vue'),
        meta: {
          notCache: true,
          title: '违章数据看板',
          root: rootName
        }
      },
      {
        path: 'car-app-white',
        name: 'car-app-white',
        component: () => import('@/views/singlePage/system-config/car-app-white/index.vue'),
        meta: {
          notCache: true,
          title: 'APP白名单',
          root: rootName
        }
      },
      {
        path: 'pay-check',
        name: 'pay-check',
        component: () => import('@/views/singlePage/operation-management/pay-check/index.vue'),
        meta: {
          notCache: true,
          title: '聚富通对账',
          root: rootName
        }
      }
    ]
  }
]
