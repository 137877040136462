/*
 * @Description: 系统配置
 * @Author: yaoxiaoxiao
 * @Date: 2021-07-08 10:04:32
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-12-25 09:51:26
 */

import Main from '../../views/main.vue'
const rootName = 'system-config'

export default [
  {
    path: '/system-config',
    name: rootName,
    component: Main,
    meta: {
      notCache: true,
      title: '系统配置'
    },
    children: [
      {
        path: 'app-update',
        name: 'app-update',
        component: () => import('@/views/singlePage/system-config/app-update/index.vue'),
        meta: {
          notCache: true,
          title: 'app更新配置',
          root: rootName
        }
      },
      {
        path: 'lock-the-car',
        name: 'car-auto-lock-settings',
        component: () => import('@/views/singlePage/system-config/lock-the-car/index.vue'),
        meta: {
          notCache: true,
          title: '平台锁车设置',
          root: rootName,
          icon: 'el-icon-lock'
        }
      },
      {
        path: 'tpm',
        name: 'tpm',
        component: () => import('@/views/singlePage/system-config/tpm/index.vue'),
        meta: {
          notCache: true,
          title: '设备产品维护',
          root: rootName,
          icon: 'el-icon-lock'
        }
      },
      {
        path: 'violation-api',
        name: 'violation-api',
        component: () => import('@/views/singlePage/system-config/violation-api/index.vue'),
        meta: {
          title: '违章API',
          root: rootName
        }
      },
      {
        path: 'auto-brand',
        name: 'auto-brand',
        component: () => import('@/views/singlePage/system-config/auto-brand/index.vue'),
        meta: {
          notCache: true,
          title: '汽车品牌',
          root: rootName
        }
      },
      {
        path: 'backend-collection',
        name: 'backend-collection',
        component: () => import('@/views/singlePage/system-config/backend-collection/index.vue'),
        meta: {
          notCache: true,
          title: '后台收款配置',
          root: rootName
        }
      },
      {
        path: 'client-monitoring',
        name: 'client-monitoring',
        component: () => import('@/views/singlePage/system-config/client-monitoring/index.vue'),
        meta: {
          notCache: true,
          title: '客户端监控',
          root: rootName
        }
      }
    ]
  }
]
