
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { getIndexConfig, getTableConfig, inputList } from './config'
import { usePagination } from '@/hooks/usePagination'
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import {
  enterpriseViolationSettingQuery,
  dropdownOfEnterprise,
  page,
} from './api'
import { toResult } from '@/utils/toResult'
import { btnName } from '@/utils'
export default defineComponent({
  components: {
    CSearchList,
    CTable,
  },
  setup() {
    const remoteData1 = reactive({
      options: [],
      isLoading: false,
      remoteMethod: (val) => {
        dropdownOfEnterprise({ value: val }).then((res) => {
          remoteData1.options = res.data.map((item) => {
            return {
              label: item.value,
              value: item.key,
            }
          })
        })
      },
    })
    const remoteData2 = reactive({
      options: [],
      isLoading: false,
      remoteMethod: (val) => {
        enterpriseViolationSettingQuery({ orgName: val }).then((res) => {
          remoteData2.options = res.data.map((item) => {
            return {
              label: item.orgName,
              value: item.id,
            }
          })
        })
      },
    })
    const data = reactive({
      inputList: inputList(
        { ...toRefs(remoteData1) },
        { ...toRefs(remoteData2) }
      ),
      ...getIndexConfig(),
    })

    const tableConfig = reactive({
      ...getTableConfig(),
    })

    const getListFn = async () => {
      const p = {
        ...data.queryForm,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize,
      }

      tableConfig.loading = true
      const [err, res] = await toResult(page(p))
      tableConfig.loading = false
      if (err) return
      tableConfig.data = res.list || []
      tableConfig.pagination.total = res.total
    }

    // 查询
    const handleSearch = (params) => {
      tableConfig.pagination.currentPage = 1
      params && (data.queryForm = { ...params })
      getListFn()
    }

    // 重置
    const handleReset = (val) => {
      data.queryForm = val
    }

    const { pageCurrentChange, pageSizeChange } = usePagination(
      tableConfig.pagination,
      () => {
        getListFn()
      }
    )

    onMounted(() => {
      handleSearch({})
    })

    return {
      ...toRefs(data),
      handleSearch,
      btnName,
      handleReset,
      tableConfig,
      pageCurrentChange,
      pageSizeChange,
      getListFn,
    }
  },
})
