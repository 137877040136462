
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { violationData } from './api'
import { toResult } from '@/utils/toResult'
import { btnName } from '@/utils'
import DetailModal from './detail.vue'
import moment from 'moment'
export default defineComponent({
  components: { DetailModal },
  setup() {
    const data = reactive({
      monthValue: '',
      tableData: [],
      isDetial: false,
      time: '',
      titlename: '',
      type: 0,
    })

    const getListFn = async () => {
      if (data.monthValue !== '' && data.monthValue !== null) {
        const p = {
          time: moment(data.monthValue).format('YYYY-MM'),
        }

        const [err, res] = await toResult(violationData(p))
        data.tableData = res

        if (err) return
      }
    }
    const detail = (time, type, titlename) => {
      data['isDetial'] = !data['isDetial']
      data.time = time
      data.type = type
      data.titlename = titlename
    }
    const handleChange = () => {
      getListFn()
    }
    onMounted(() => {
      getListFn()
    })

    return {
      ...toRefs(data),
      btnName,
      getListFn,
      handleChange,
      detail,
    }
  },
})
