
import { toResult } from '@/utils/toResult'
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, ref, toRefs, watchEffect } from 'vue'
// import { editAPI } from '../mock'
import { editAPI } from '../api'
export default defineComponent({
  name: 'parameter-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    // 表单常规非空校验
    const required = {
      required: true,
      message: '请输入',
      trigger: 'blur',
    }

    // 当前组件响应状态
    const state = reactive({
      isShow: false,
      isLoading: false,
      title: '修改参数',
      constFormData: null,
      formData: null,
      formRef: ref(),
      rules: {
        paramValue: required,
        paramDes: required,
      },
    })

    // 监听
    watchEffect(() => {
      state.isShow = props.visible
      state.formData = {
        paramId: props.row.paramId,
        paramValue: props.row.paramValue,
        paramDes: props.row.paramDes,
      }
      state.constFormData = {
        paramName: props.row.paramName,
        paramTag: props.row.paramTag,
      }
    })

    /**
     * @description: 执行关闭
     * @return {void}
     */
    const handleClose = (): void => {
      state.isShow = false
      emit('update:visible')
    }

    /**
     * @description: 异步发送请求
     * @param {object} parmas 请求入参
     * @return {void} Promise<void>
     */
    const handleSubmit = async (params: object): Promise<void> => {
      state.isLoading = true
      const [err, data, msg] = await toResult(editAPI(params))
      state.isLoading = false
      if (err) return
      console.log(data)
      ElMessage.success(msg)
      emit('update:list')
      handleClose()
    }

    /**
     * @description: 异步提交数据校验
     * @param {*}
     * @return {void}
     */
    const handleValidate = (): void => {
      state.formRef.validate((valid: boolean): void => {
        valid && handleSubmit(state.formData)
      })
    }

    return {
      ...toRefs(state),
      handleClose,
      handleValidate,
    }
  },
})
