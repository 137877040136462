
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { get, edit } from './api'
import { required } from '@/utils/rules'
import { toResult } from '@/utils/toResult'
import { ElMessage } from 'element-plus'
import moment from 'moment'
export default defineComponent({
  components: {},
  setup() {
    // 页面基础变量
    const data = reactive({
      formRef: undefined,
      formData: ref<any>({}),
      rules: {
        state: [required],
        content: [
          required,
          { min: 2, message: '最小长度为2', trigger: 'blur' },
        ],
        expireTime: [required],
      },
    })

    // 列表数据
    const getListFn = async () => {
      const [err, res] = await toResult(get({}))
      if (err) return

      data.formData = res || []
      console.log(data.formData.state)
    }

    /**
     * @description: 校验表单
     * @param {*}
     * @return {*}
     */
    const onValidate = () => {
      data.formRef.validate(async (valid) => {
        if (valid) {
          const p: any = {
            id: data.formData.id,
            state: data.formData.state,
            content: data.formData.content,
            expireTime: moment(data.formData.expireTime).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
          }
          const [err] = await toResult(edit(p))
          if (err) return false
          getListFn()
          ElMessage.success('操作成功')
        }
      })
    }

    onMounted(() => {
      getListFn()
    })

    return {
      ...toRefs(data),
      getListFn,
      onValidate,
    }
  },
})
