/*
 * @Descripttion: 
 * @Author: panyufeng
 * @Date: 2024-12-04 10:32:39
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-12-04 10:36:14
 */
import service from '@/utils/request'

export const get = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/notice/get',
    data: pramas
  })
export const edit = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/notice/edit',
    data: pramas
  })
