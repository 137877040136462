/*
 * @Description: config
 * @Author: yaoxiaoxiao
 * @RearEnd: 郑晓
 * @Date: 2021-03-30 10:55:40
 * @LastEditors: panyufeng
 * @LastEditTime: 2025-01-13 09:42:09
 */
// 搜索配置
export const inputList = () => [
  {
    type: 'input',
    label: '参数名称',
    key: 'paramNameFuzzy',
    placeholder: '请输入参数名称',
    labelWidth: 100,
    inputWidth: 200,
    maxlength: 30,
    showWordLimit: true
  }
]

// 表格配置
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    // height: '500px',
    // table 是否为斑马纹
    stripe: false,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '编号',
    // table 数据
    data: [
      {
        operationContent: 'dasdas'
      }
    ]
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'paramName',
      label: '名称',
      width: '140'
    },
    {
      key: 'paramId',
      label: '标识',
      width: '80'
    },
    {
      key: 'paramValue',
      label: '参数值',
      width: '80'
    },
    {
      key: 'paramDes',
      label: '参数描述',
      width: '380'
    },
    {
      key: 'operator',
      label: '操作用户',
      width: '150'
    },
    {
      key: 'operateTime',
      label: '操作时间',
      width: '160'
    },
    {
      key: 'action',
      label: '操作',
      width: '120',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
