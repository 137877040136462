
import { toResult } from '@/utils/toResult'
import { ElMessage } from 'element-plus'
import { addSystemParam } from '../api'
import { defineComponent, reactive, ref, toRefs, watchEffect } from 'vue'
export default defineComponent({
  name: 'parameter-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // 表单常规非空校验
    const required = {
      required: true,
      message: '请输入',
      trigger: 'blur',
    }

    // 当前组件响应状态
    const state = reactive({
      isShow: false,
      title: '新增参数',
      formData: ref<any>({}),
      formRef: ref(),
      typeOpt: [
        {
          label: '其他',
          value: '0',
        },
        {
          label: '短信验证码',
          value: '1',
        },
        {
          label: '企业初始密码',
          value: '2',
        },
        {
          label: '文件',
          value: '3',
        },
      ],
      rules: {
        paramId: required,
        paramName: required,
        paramTag: required,
        paramValue: required,
        paramDes: required,
      },
    })

    // 监听
    watchEffect(() => {
      state.isShow = props.visible
    })

    /**
     * @description: 执行关闭
     * @return {void}
     */
    const handleClose = (): void => {
      state.isShow = false
      emit('update:visible')
    }

    /**
     * @description: 异步发送请求
     * @param {object} parmas 请求入参
     * @return {void} Promise<void>
     */
    const handleSubmit = async (params: object): Promise<void> => {
      const [err, data, msg] = await toResult(addSystemParam(params))
      if (err) return
      console.log(data)
      ElMessage.success(msg)
      emit('update:list')
      handleClose()
    }

    /**
     * @description: 异步提交数据校验
     * @param {*}
     * @return {void}
     */
    const handleValidate = (): void => {
      state.formRef.validate((valid: boolean): void => {
        valid && handleSubmit(state.formData)
      })
    }

    return {
      ...toRefs(state),
      handleClose,
      handleValidate,
    }
  },
})
