
import CTable from '@/components/c-table'
import { defineComponent, reactive, toRefs, watchEffect, onMounted } from 'vue'
import { usePagination } from '@/hooks/usePagination'
import { detailData } from './api'
import { getTableConfig } from './config'
import { toResult } from '@/utils/toResult'
export default defineComponent({
  components: { CTable },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
    time: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Object,
      default: () => {},
    },
    titlename: {
      type: Object,
      default: () => {},
    },
  },
  // emits: ['update:modelValue'],
  setup(props, { emit }) {
    const data = reactive({
      visible: false,
      titlename: '记录明细（',
    })
    const tableConfig = reactive({
      ...getTableConfig(),
    })

    watchEffect(() => {
      data.visible = props.modelValue
    })
    const getListFn = async () => {
      const p = {
        time: props.time,
        type: props.type,
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize,
      }
      const [err, res] = await toResult(detailData(p))
      if (err) return
      console.log(res)

      tableConfig.data = res.list || []
      tableConfig.pagination.total = res.total
    }
    /**
     * @description: 关闭dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      data.visible = false
      emit('update:modelValue', false)
    }
    const init = () => {
      console.log(props.time)
      console.log(props.type)
      console.log(props.titlename)
      data.titlename = data.titlename + props.titlename + ')'
      getListFn()
    }
    const { pageCurrentChange, pageSizeChange } = usePagination(
      tableConfig.pagination,
      () => {
        getListFn()
      }
    )
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(data),
      tableConfig,
      pageCurrentChange,
      pageSizeChange,
      onCancel,
    }
  },
})
